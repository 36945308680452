<template>
    <el-card>
        <el-row :gutter="20">
            <el-col :span="12">
                <!-- 搜索与添加 -->
                <el-input
                    v-model="query"
                    :placeholder="$t('tips.enter')" 
                    class="input-with-select" 
                    @clear="clearQuery"
                    clearable>
                    <el-button slot="append" icon="el-icon-search" @click="queryCategory"></el-button>
                </el-input>
            </el-col>

            <el-col :span="24" class="mt20">
                <el-button type="danger" :disabled="disabled" size="mini" @click="removeCategory(deleteCategory)">{{$t('button.del')}}</el-button>
                <el-button type="primary" size="mini" @click="addDialogVisible = true">{{$t('button.add_category')}}</el-button>
                <el-tag class="right"> {{$t('tips.selected') + selected + $t('tips.item')}} / {{ $t('tips.total') + total + $t('tips.item')}} </el-tag>
            </el-col>

            <el-col :span="24">
                <el-table
                    ref="multipleTable"
                    v-loading="loading"
                    :data="categoryList"
                    @selection-change="categoryChange"
                    size="small"
                    tooltip-effect="dark">
                    <el-table-column
                        type="selection">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="分类">
                    </el-table-column>
                    <el-table-column
                        label="状态">
                        <template v-slot="scope">
                            <el-switch
                                @change="changeSwitch(scope.row)"
                                v-model="scope.row.status">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="username"
                        label="创建用户">
                    </el-table-column>
                    <el-table-column
                        prop="create_time"
                        label="创建时间"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="update_time"
                        label="修改时间"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column 
                        width="200"
                        label="操作">
                        <template v-slot="scope">
                            <el-button type="primary" icon="el-icon-edit" size="mini" @click="getCategory(scope.row)">{{$t('button.edit')}}</el-button>
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeCategory(scope.row.id)">{{$t('button.del')}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>

        <el-pagination
            class="mt20"
            :hide-on-single-page="onePage"
            :total="total"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :page-sizes="pageSizes"
            @size-change="categorySizeChange"
            @current-change="categoryCurrentChange"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>

        


        <!-- 添加分类对话框 -->
        <el-dialog
            :title="$t('button.add_category')"
            :visible.sync="addDialogVisible"
            @close="dialogClose('add')"
            width="50%">
            <create-form 
                ref="add"
                size='medium'
                :createData="addDate"
                :createForm="addForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="add">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>

        <!-- 修改分类信息 -->
        <el-dialog
            :title="$t('button.edit')"
            :visible.sync="editDialogVisible"
            width="50%">
            <create-form 
                ref="edit"
                size='medium'
                :createData="editDate"
                :createForm="editForm"
                :rule="fromRules">
            </create-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">{{$t('button.cancel')}}</el-button>
                <el-button type="primary" @click="edit">{{$t('button.ok')}}</el-button>
            </span>
        </el-dialog>
    </el-card>
</template>

<script>
import _ from 'lodash'; // 深拷贝
import config from '@/config';
import { getCategory, addCategory, removeCategory, editCategory, changeCategory } from '@/api/console/admin';
import CreateForm from '@/components/Form';

export default {
    data() {
        return {
            loading: true,
            // search
            query: '',
            // add
            addDialogVisible: false,
            // 添加分类
            addDate:{
                name: '',
                desc: '',
                // status: true
            },
            // edit
            editDialogVisible: false,
            editDate: {},
            categoryList: [],
            // 分页
            total: 0,           // 总条数
            pageSize: config.default.pageLimit,        // 分页大小
            pageSizes: config.default.pageSizes,
            currentPage: 1,     // 当前页
            onePage: false,
            // 删除数据
            deleteCategory: '',
            disabled: true,
            selected: 0
        }
    },
    components: {
        CreateForm,
    },
    created() {
        this.getCategoryAll()
    },
    computed: {
        // add
        addForm() {
            return [
                { 
                    type: 'Input',
                    label: this.$t('form.name'),
                    prop: 'name',
                    placeholder: this.$t('form.name')
                },
                { 
                    type: 'textarea',
                    label: this.$t('form.desc'),
                    prop: 'desc',
                    row: '5',
                    max: 30,
                    placeholder: this.$t('form.desc')
                },
                { 
                    type: 'Switch',
                    label: this.$t('form.category.add.status'),
                    prop: 'status'
                }
            ]
        },
        // add
        editForm() {
            return [
                { 
                    type: 'Input',
                    label: this.$t('form.name'),
                    prop: 'name',
                    placeholder: this.$t('form.name')
                },
                { 
                    type: 'textarea',
                    label: this.$t('form.desc'),
                    prop: 'desc',
                    row: '5',
                    max: 30,
                    placeholder: this.$t('form.desc')
                },
                { 
                    type: 'Switch',
                    label: this.$t('form.category.add.status'),
                    prop: 'status'
                }
            ]
        },
        // 表单验证
        fromRules() {
            const rules = {
                name: [
                    { required: true, message: this.$t('validate.empty'), trigger: 'blur' },
                    { min: 2, max: 10, message: this.$t('validate.max_length') + '2~10', trigger: 'blur' }
                ],
            }
            return rules
        }
    },
    
    methods: {
        // 关闭对话框
        dialogClose(ref) {
            // this.$refs[ref].resetFields()
            this.$nextTick(() => { 
                this.$refs[ref].clearValidate()
            })
        },
        // 搜索
        queryCategory() {
            if(this.query) {
                this.currentPage = 1
                return this.getCategoryAll()
            }
            return this.$message.warning(this.$t('tips.search'))
        },
        // 清除搜索
        clearQuery() {
            this.query = ''
            this.getCategoryAll()
        },
        async add() {
            let valid = await this.$refs.add.validate()
            if( !valid ) return;
            addCategory(this.addDate).then(res => {
                this.$message.success(res.data.msg)
                this.addDialogVisible = false
                this.dialogClose('add')
                this.getCategoryAll()
            }).catch(err => {
                
            })
        },
        getCategoryAll() {
            this.loading = true

            const page = this.currentPage,
                  size = this.pageSize,
                  query = this.query
            getCategory({page, size, query}).then(res => {
                res.data.data.list.forEach(item => {
                    item.status = item.status ? true : false
                })
                this.categoryList = res.data.data.list
                this.total = res.data.data.total
                if(this.total <= this.pageSize) {
                    this.onePage = true
                }
                this.loading = false
            }).catch(err => {

            })
        },
        getCategory(item) {
            this.editDialogVisible = true
            // 深拷贝对象
            this.editDate = _.cloneDeep(item)
        },
        // 修改分类信息
        async edit() {
            let valid = await this.$refs.edit.validate()
            if( !valid ) return;
            editCategory(this.editDate).then(res => {
                if(res.data.code === 1) {
                    this.editDialogVisible = false
                    this.$message.success(this.$t('tips.success'));
                    this.getCategoryAll()
                }
            }).catch(err => {

            })
        },
        // 删除选中的id
        removeCategory(id) {
            if(!id) {
                this.$message.error(this.$t('tips.not_selected'))
                return
            }
            this.$confirm(this.$t('tips.del'), this.$t('tip'), {
                type: 'warning'
            }).then(() => {
                removeCategory(id).then(res => {
                    if(res.data.code === 1) {
                        this.$message({
                            type: 'success',
                            message: this.$t('tips.success')
                        });
                        this.getCategoryAll()
                    }
                }).catch(err => {

                })
            }).catch(() => {
                
            });
        },
        // 状态改变
        changeSwitch(data) {
            console.log(data.status)
            let id = data.id
                status = data.status ? 1 : 0
            changeCategory(id, status).then(res => {
                if(res.data.code === 1) {
                    this.$message.success(this.$t('tips.success'));
                    // this.getCategoryAll()
                }
            }).catch(err => {

            })
        },
        // 选中的分类
        categoryChange(selection) {
            let data = []
            selection.forEach((v, i) => {
                data.push(v.id)
            })
            this.selected = data.length
            this.deleteCategory = data.join(',')
            
            if(this.deleteCategory) {
                this.disabled = false
            } else { 
                this.disabled = true
            }
        },
        // 分页
        categorySizeChange(pagesize) {
            this.pageSize = pagesize
            this.getCategoryAll()
        },
        categoryCurrentChange(page) {
            this.currentPage = page
            this.getCategoryAll()
        }
    }
}
</script>